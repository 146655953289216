import React, { useState, useEffect, ReactElement } from "react";
import {
    Button,
    FormControl,
    IconButton,
    InputBase,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Tooltip,
    Typography,
} from "@mui/material";
import { DataGrid, esES, GridColumns } from "@mui/x-data-grid";
import { Box, Grid, Modal } from "@mui/material";
import {
    ArrowForwardIos,
    Close,
    FilterAlt,
    InfoOutlined,
} from "@mui/icons-material";
import { weekgoalDetail } from "../../actions/comercial/metas";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import FilterMultiSelect from "../reports/FilterMultiSelect";
import Link from "next/link";

export const WeekGoalModal = ({
    open,
    close,
    user,
    dateDisplay,
    periodo = "Todos",
}) => {
    const [searched, setSearched] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [columns, setColumns] = useState([]);
    const [list, setList] = useState([]);
    const [listTable, setListTable] = useState([]);

    // const requestSearch = (searchVal) => {

    //     const filteredRows = generateListTable(list, open).filter((row) => {
    //         return Object.keys(row).filter((key) => {
    //             if(!row[key] || typeof row[key] != 'string') return false
    //             return row[key].toLowerCase().includes(searchVal.toLowerCase())
    //         }).length > 0
    //     })
    //     setListTable(filteredRows)

    // }

    let generateListTable = (baseList, type) => {
        let result = [];
        switch (type) {
            case "contacts":
                baseList.forEach((goal) => {
                    if (!goal.prospects) return "";
                    goal.prospects.map((prospect) => {
                        if (prospect.phones) {
                            prospect.phone = prospect.phones[0];
                        } else {
                            prospect.phone = "";
                        }
                        if (prospect.leads) {
                            prospect.lead = prospect.leads[0].source;
                        } else {
                            prospect.lead = "";
                        }
                        if (prospect.agent) {
                            prospect.vendedor = `${prospect.agent.firstName} ${prospect.agent.lastName}`;
                        } else {
                            prospect.vendedor = "";
                        }
                    });
                    result = [...result, ...goal.prospects];
                });
                break;
            case "tracings":
                baseList.forEach((goal) => {
                    if (!goal.tracings) return "";

                    goal.tracings.map((tracing) => {
                        if (tracing.user) {
                            tracing.vendedor = `${tracing.user.name} ${tracing.user.lastname}`;
                        } else {
                            tracing.vendedor = "";
                        }
                    });
                    result = [...result, ...goal.tracings];
                });
                break;
            case "sales":
                baseList.forEach((goal) => {
                    if (!goal.sales) return "";
                    goal.sales.map((sale) => {
                        if (sale.user) {
                            sale.vendedor = `${sale.user.name} ${sale.user.lastname}`;
                        } else {
                            sale.vendedor = "";
                        }
                    });
                    result = [...result, ...goal.sales];
                });
                break;
        }
        return result;
    };

    let reloadData = () => {
        weekgoalDetail("calculate", user, dateDisplay)
            .then((result) => {
                if (result && result.goals.length == 0) {
                    setList([]);
                }
                let { goals } = result;
                setList(generateListTable([...goals], open));
            })
            .catch(() => {});
    };

    const [vendedorFilter, setVendedorFilter] = useState(["all"]);
    const [originFilter, setOiriginFilter] = useState(["all"]);
    const [dateFilter, setDateFilter] = useState("all");
    const [statusFilter, setStatusFilter] = useState(["all"]);
    const [fuentesFilter, setFuenteFilter] = useState(["all"]);
    const [agentFilter, setAgentFilter] = useState(["all"]);

    const [origenes, setOrigenes] = useState([]);
    const [vendedores, setVendedores] = useState([]);
    const [estados, setEstados] = useState([]);
    const [fuentes, setFuentes] = useState([]);
    const [agentes, setAgentes] = useState([]);
    const [showFilter, toggleFilter] = useState("none");
    const [filtros, setFiltros] = useState([]);
    const [listTableFiltered, setListTableFiltered] = useState(listTable);

    useEffect(() => {
        if (open) {
            setList([]);
            switch (open) {
                case "contacts":
                    setFiltros(["Fecha", "Agente", "Origen"]);
                    setTitle("Contactos");
                    setColumns([
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "created",
                            headerName: "Fecha creación",
                            description: "Fecha y hora de creación",
                            width: 150,
                            renderCell: (params) => {
                                return (
                                    moment(new Date(params.value)).format("L") +
                                    " " +
                                    moment(new Date(params.value)).format("LT")
                                );
                            },
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "phone",
                            headerName: "Telefono",
                            description: "Telefono del contacto",
                            width: 150,
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "firstName",
                            headerName: "Nombre",
                            description: "Nombre del contacto",
                            width: 150,
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "lastName",
                            headerName: "Apellido",
                            description: "Apellido del contacto",
                            width: 150,
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "vendedor",
                            headerName: "Vendedor",
                            width: 150,
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "lead",
                            headerName: "Origen",
                            description: "Grupo del contacto",
                            width: 150,
                        },
                    ]);

                    break;
                case "tracings":
                    setFiltros(["Fecha", "Vendedor", "Duracion"]);
                    setTitle("Seguimientos");
                    setColumns([
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "date",
                            headerName: "Fecha",
                            description: "Fecha",
                            width: 150,
                            renderCell: (params) => {
                                return moment(new Date(params.value)).format(
                                    "L"
                                );
                            },
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "phone",
                            headerName: "Telefono",
                            description: "Telefono",
                            width: 150,
                            renderCell: (params) => {
                                return params.value;
                            },
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "maxDuration",
                            headerName: "Duracion",
                            description: "Duracion",
                            width: 100,
                            renderCell: (params) => {
                                let dateObj = new Date(params.value * 1000);
                                let hours = dateObj.getUTCHours();
                                let minutes = dateObj.getUTCMinutes();
                                let seconds = dateObj.getSeconds();

                                let timeString =
                                    hours.toString().padStart(2, "0") +
                                    ":" +
                                    minutes.toString().padStart(2, "0") +
                                    ":" +
                                    seconds.toString().padStart(2, "0");

                                return timeString;
                            },
                        },

                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "validity",
                            headerName: "Valido",
                            description: "Valido",
                            width: 100,
                            renderCell: (params) => {
                                if (params.value) {
                                    return "Valido";
                                } else {
                                    return "Invalido";
                                }
                            },
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "vendedor",
                            headerName: "Vendedor",
                            width: 150,
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "noteContent",
                            headerName: "Nota",
                            description: "Nota",
                            width: 350,
                            // renderCell: (params) => {
                            // }
                        },
                    ]);
                    break;
                case "sales":
                    setFiltros(["Fecha", "Vendedor", "Estado"]);

                    setTitle("Ventas");

                    setColumns([
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "date_sale",
                            headerName: "Fecha de venta",
                            description: "Fecha de la venta",
                            width: 150,
                            renderCell: (params) => {
                                return moment(new Date(params.value)).format(
                                    "L"
                                );
                            },
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "date",
                            headerName: "Fecha ingreso",
                            description: "Fecha de ingreso",
                            width: 150,
                            renderCell: (params) => {
                                return moment(new Date(params.value)).format(
                                    "L"
                                );
                            },
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "vendedor",
                            headerName: "Vendedor",
                            description: "Vendedor",
                            width: 150,
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "ticket_id",
                            headerName: "ID",
                            description: "ID",
                            width: 130,
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "ticket_sale_id",
                            headerName: "ID Venta",
                            description: "ID Venta",
                            width: 130,
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "phone",
                            headerName: "Telefono",
                            description: "Telefono",
                            width: 150,
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "sold",
                            headerName: "Vendido",
                            description: "Vendido",
                            width: 100,
                            renderCell: (params) => {
                                if (params.value) {
                                    return "Vendido";
                                } else {
                                    return "No vendido";
                                }
                            },
                        },

                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "status",
                            headerName: "Estado",
                            description: "Estado",
                            width: 100,
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "type",
                            headerName: "Fuente",
                            description: "Fuente",
                            width: 100,
                            renderCell: (params) => {
                                let res = params.value;
                                res =
                                    res[0].toUpperCase() +
                                    res.substr(1).toLowerCase();
                                res.slice(1, 1);
                                return res;
                            },
                        },
                        {
                            disableColumnMenu: true,
                            sortable: false,
                            field: "name",
                            headerName: "Nombre cliente",
                            description: "Nombre cliente",
                            width: 200,
                            renderCell: (params) => {
                                let res = params.value;
                                res =
                                    res[0].toUpperCase() +
                                    res.substr(1).toLowerCase();
                                res.slice(1, 1);
                                return res;
                            },
                        },
                    ]);
                    break;
            }
            reloadData();
        }
    }, [open]);

    useEffect(() => {
        if (open && list) {
            let origenesMap = [];
            let vendedoresMap = [];
            let estadosMap = [];
            let fuentesMap = [];
            let agentesMap = [];
            list.map((val) => {
                if (val.leads && val.leads[0].source && val.leads[0]) {
                    if (!origenesMap.includes(val.leads[0].source)) {
                        origenesMap.push(val.leads[0].source);
                    }
                }
                if (val.user && val.user.name && val.user.lastname) {
                    let nombre = `${val.user.name} ${val.user.lastname}`;
                    if (!vendedoresMap.includes(nombre)) {
                        vendedoresMap.push(nombre);
                    }
                }

                if (val.status) {
                    if (!estadosMap.includes(val.status)) {
                        estadosMap.push(val.status);
                    }
                }

                if (val.type) {
                    if (!fuentesMap.includes(val.type)) {
                        fuentesMap.push(val.type);
                    }
                }

                if (
                    val.firstAgent &&
                    val.firstAgent.firstName &&
                    val.firstAgent.lastName
                ) {
                    let nombre = `${val.firstAgent.firstName} ${val.firstAgent.lastName}`;
                    val.vendedor = nombre;
                    if (!agentesMap.includes(nombre)) {
                        agentesMap.push(nombre);
                    }
                }
            });
            setFuentes(fuentesMap);
            setEstados(estadosMap);
            setVendedores(vendedoresMap);
            setOrigenes(origenesMap);
            setAgentes(agentesMap);
            //=======================================

            //Filtramos la lista segun los filtros seleccionados
            let aux = list.filter((val) => {
                if (searched) {
                    let keys_cumplen = Object.keys(val).filter((key) => {
                        if (!val[key] || typeof val[key] != "string") {
                            return false;
                        } else {
                            return val[key]
                                .toLowerCase()
                                .includes(searched.toLocaleLowerCase());
                        }
                    });

                    if (keys_cumplen.length == 0) {
                        return false;
                    }
                }
                if (originFilter[0] != "all" && originFilter.length > 0) {
                    if (val.leads && val.leads[0].source && val.leads[0]) {
                        if (!originFilter.includes(val.leads[0].source)) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }

                if (vendedorFilter[0] != "all" && vendedorFilter.length > 0) {
                    if (val.user && val.user.name && val.user.lastname) {
                        let nombre = `${val.user.name} ${val.user.lastname}`;
                        if (!vendedorFilter.includes(nombre)) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }

                if (statusFilter[0] != "all" && statusFilter.length > 0) {
                    if (val.status) {
                        if (!statusFilter.includes(val.status)) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }

                // if (fuentesFilter[0] != 'all' && fuentesFilter != []) {
                //     if (val.type) {
                //         if (!fuentesFilter.includes(val.type)) {
                //             return false;
                //         }
                //     } else {
                //         return false;
                //     }
                // }

                if (agentFilter[0] != "all" && agentFilter.length > 0) {
                    if (
                        val.firstAgent &&
                        val.firstAgent.firstName &&
                        val.firstAgent.lastName
                    ) {
                        let nombre = `${val.firstAgent.firstName} ${val.firstAgent.lastName}`;
                        if (!agentFilter.includes(nombre)) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }

                if (dateFilter != "all") {
                    if (val.created) {
                        let fechaVal = moment(val.created).format("YYYY-MM-DD");
                        let fechaActual = moment().format("YYYY-MM-DD");

                        if (dateFilter == "hoy") {
                            if (fechaVal != fechaActual) {
                                return false;
                            }
                        }
                        if (dateFilter == "ayer") {
                            if (
                                moment(fechaVal).date() !=
                                moment(fechaActual).date() - 1
                            ) {
                                return false;
                            }
                        }
                        if (dateFilter == "7dias") {
                            if (
                                moment(val.created).isBefore(
                                    moment(new Date().getTime() - 84000000 * 8)
                                )
                            ) {
                                return false;
                            }
                        }
                        if (dateFilter == "14dias") {
                            if (
                                moment(val.created).isBefore(
                                    moment(new Date().getTime() - 84000000 * 14)
                                )
                            ) {
                                return false;
                            }
                        }
                    }
                    if (val.timestamp) {
                        let fechaVal = moment(val.timestamp).format(
                            "YYYY-MM-DD"
                        );
                        let fechaActual = moment().format("YYYY-MM-DD");

                        if (dateFilter == "hoy") {
                            if (fechaVal != fechaActual) {
                                return false;
                            }
                        }
                        if (dateFilter == "ayer") {
                            if (
                                moment(fechaVal).date() !=
                                moment(fechaActual).date() - 1
                            ) {
                                return false;
                            }
                        }
                        if (dateFilter == "7dias") {
                            if (
                                moment(val.timestamp).isBefore(
                                    moment(new Date().getTime() - 84000000 * 8)
                                )
                            ) {
                                return false;
                            }
                        }
                        if (dateFilter == "14dias") {
                            if (
                                moment(val.timestamp).isBefore(
                                    moment(new Date().getTime() - 84000000 * 14)
                                )
                            ) {
                                return false;
                            }
                        }
                    }
                    if (val.date) {
                        let fechaVal = moment(val.date).format("YYYY-MM-DD");
                        let fechaActual = moment().format("YYYY-MM-DD");

                        if (dateFilter == "hoy") {
                            if (fechaVal != fechaActual) {
                                return false;
                            }
                        }
                        if (dateFilter == "ayer") {
                            if (
                                moment(fechaVal).date() !=
                                moment(fechaActual).date() - 1
                            ) {
                                return false;
                            }
                        }
                        if (dateFilter == "7dias") {
                            if (
                                moment(val.date).isBefore(
                                    moment(new Date().getTime() - 84000000 * 8)
                                )
                            ) {
                                return false;
                            }
                        }
                        if (dateFilter == "14dias") {
                            if (
                                moment(val.date).isBefore(
                                    moment(new Date().getTime() - 84000000 * 14)
                                )
                            ) {
                                return false;
                            }
                        }
                    }
                    // if (val.date_sale) {
                    //     let fechaVal = moment(val.date_sale).format('YYYY-MM-DD');
                    //     let fechaActual = moment().format('YYYY-MM-DD');

                    //     if (dateFilter == 'hoy') {
                    //         if ((fechaVal) != fechaActual) {
                    //             return false
                    //         }
                    //     }
                    //     if (dateFilter == 'ayer') {
                    //         if (moment(fechaVal).date() != moment(fechaActual).date() - 1) {
                    //             return false
                    //         }
                    //     }
                    //     if (dateFilter == '7dias') {

                    //         if (moment(val.date_sale).isBefore(moment(new Date().getTime()-(84000000 * 8) ) ) ) {
                    //             return false
                    //         }
                    //     }
                    //     if (dateFilter == '14dias') {
                    //         if (moment(val.date_sale).isBefore(moment(new Date().getTime()-(84000000 * 14) ) ) ) {
                    //             return false
                    //         }
                    //     }

                    // }
                }

                return true;
            });
            setListTable(aux);
            let rows = {};
        }
    }, [
        list,
        agentFilter,
        fuentesFilter,
        statusFilter,
        vendedorFilter,
        originFilter,
        dateFilter,
        searched,
    ]);

    var getURLBYOPEN = () => {
        switch (open) {
            case "contacts":
                return "/reportes/prospect";
            case "tracings":
                return "/reportes/tracings";
            case "sales":
                return "/reportes/receipts";
            default:
                return "/reports";
        }
    };
    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    "& .header-meta, .MuiDataGrid-columnsContainer": {
                        backgroundColor: "#0080ff",
                        color: "#fff",
                    },
                    "& .header-meta .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: 600,
                    },
                    "& .MuiDataGrid-columnHeaderWrapper ": {
                        height: 20,
                    },
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    position: "absolute" as "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "100vh",
                    maxHeight: "700px",
                    maxWidth: "1150px",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: "15px",
                    p: 3,
                }}
            >
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Grid item mb={2}>
                        <Typography fontSize={24}>
                            {title}
                            <Tooltip
                                title={`El reporte qué estas viendo corresponde a los ${title.toLowerCase()} en la meta abierta, puedes visualizar un reporte más completo haciendo click en "Ver más"`}
                            >
                                <InfoOutlined
                                    sx={{
                                        fontSize: "12px",
                                        ml: 0.5,
                                        color: "#0080ff",
                                    }}
                                />
                            </Tooltip>
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        mb={2}
                        sx={{
                            "&:hover": {
                                color: "#3d9ffb",
                            },
                        }}
                    >
                        <Close sx={{ fontSize: "28px" }} onClick={close} />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        "@media (max-width: 479px)": { display: "block" },
                        display: "none",
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mb: 1, textTransform: "none" }}
                        onClick={() =>
                            toggleFilter(
                                showFilter == "none" ? "block" : "none"
                            )
                        }
                        endIcon={<FilterAlt />}
                    >
                        Mostrar filtros
                    </Button>
                </Grid>
                <Grid
                    direction={"column"}
                    py={1}
                    sx={{
                        "@media (max-width: 479px)": { display: showFilter },
                    }}
                >
                    <Paper
                        component="form"
                        sx={{
                            p: "2px 4px",
                            display: "inline-flex",
                            alignItems: "center",
                            width: "200px",
                            height: "45px",
                            mr: 2,
                            mt: 0.5,
                            "@media (min-width: 480px)": { mb: 1 },
                        }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            onChange={(searchVal) =>
                                setSearched(searchVal.target.value)
                            }
                            placeholder="Buscar..."
                            inputProps={{ "aria-label": "buscar" }}
                        />
                        <IconButton
                            sx={{ p: "5px", mr: 0.5 }}
                            aria-label="search"
                        >
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                    {filtros && filtros.includes("Fecha") && (
                        <FormControl
                            sx={{
                                width: "200px",
                                mr: 2,
                                mt: 1,
                                "@media (max-width: 479px)": { mt: 2, mb: 1 },
                            }}
                            size="small"
                        >
                            <InputLabel>Fecha</InputLabel>
                            <Select
                                label="Fecha"
                                onChange={(event) =>
                                    setDateFilter(event.target.value as string)
                                }
                                value={dateFilter}
                            >
                                <MenuItem value="hoy">Hoy</MenuItem>
                                <MenuItem value="ayer">Ayer</MenuItem>
                                <MenuItem value="7dias">
                                    Últimos 7 días
                                </MenuItem>
                                <MenuItem value="14dias">
                                    Últimos 14 días
                                </MenuItem>
                                <MenuItem value="all">{periodo}</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    {filtros && filtros.includes("Agente") && (
                        <FilterMultiSelect
                            title="Agente"
                            list={agentes.map((agente) => {
                                return { name: agente, code: agente };
                            })}
                            idKey={"code"}
                            setValue={(value) => setAgentFilter(value)}
                            getName={(value) => value.name}
                            value={agentFilter}
                        />
                    )}
                    {filtros && filtros.includes("Vendedor") && (
                        <FilterMultiSelect
                            title="Vendedor"
                            list={vendedores.map((vendedor) => {
                                return { name: vendedor, code: vendedor };
                            })}
                            idKey={"code"}
                            setValue={(value) => setVendedorFilter(value)}
                            getName={(value) => value.name}
                            value={vendedorFilter}
                        />
                    )}
                    {filtros && filtros.includes("Origen") && (
                        <FilterMultiSelect
                            title="Origen"
                            list={origenes.map((origen) => {
                                return { name: origen, code: origen };
                            })}
                            idKey={"code"}
                            setValue={(value) => setOiriginFilter(value)}
                            getName={(value) => value.name}
                            value={originFilter}
                        />
                    )}
                    {filtros && filtros.includes("Estado") && (
                        <FilterMultiSelect
                            title="Estado"
                            list={estados.map((estado) => {
                                return { name: estado, code: estado };
                            })}
                            idKey={"code"}
                            setValue={(value) => setStatusFilter(value)}
                            getName={(value) => value.name}
                            value={statusFilter}
                        />
                    )}
                    {filtros && filtros.includes("Fuente") && (
                        <FilterMultiSelect
                            title="Fuente"
                            list={fuentes.map((fuente) => {
                                return { name: fuente, code: fuente };
                            })}
                            idKey={"code"}
                            setValue={(value) => setFuenteFilter(value)}
                            getName={(value) => value.name}
                            value={fuentesFilter}
                        />
                    )}
                </Grid>
                <Link href={getURLBYOPEN()}>
                    <Button
                        variant="outlined"
                        sx={{ mb: 2, textTransform: "none", width: "210px" }}
                        onClick={() => {}}
                        endIcon={<ArrowForwardIos />}
                    >
                        Ver reporte completo
                    </Button>
                </Link>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    rowHeight={32}
                    headerHeight={32}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    rows={listTable.map((i, index) => ({
                        ...i,
                        id: i._id ? i._id : index + i,
                    }))}
                />
            </Box>
        </Modal>
    );
};

export default WeekGoalModal;