import axios from 'axios'
const API = process.env.API
const API_NEXT = process.env.API_NEXT
import { getCookie, getLocalStorage } from '../auth'



export const upload = (group, form) => {

    return axios.post(`${API}/goal/${group}/upload`, form,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const getDailyStats = (user) => {
    return axios.get(`${API}/weekgoal/stats/${user}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}


export const getDailyAlerts = (user) => {
    return axios.get(`${API}/weekgoal/alerts/${user}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}


export const getDailyAlertsGroup = (group) => {
    return axios.get(`${API}/weekgoal/alerts/group/${group}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}


export const uploadUser = (form) => {

    return axios.post(`${API}/goal/upload`, form,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}


export const downloadGoal = (type, user, week = false, months, grouped = false) => {
    let query = []
    if (type) {
        query.push(`type=${type}`)
    }
    if (user) {
        query.push(`user=${user}`)
    }

    if (months) {
        query.push(`months=${months.join(',')}`)
    }

    if (grouped) {
        query.push('grouped=true')
    }

    if (week) {
        query.push(`date=now`)
    }
    return axios({
        url: `${API}/weekgoal/download${query.length > 0 ? '?' + query.join('&') : ''}`,
        method: 'GET',
        responseType: 'blob', // important
        headers: {
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
}

export const downloadGoalTemplate = (year, month, week) => {
    return axios({
        url: `${API}/weekgoal/download/${year}/${month}/template.xlsx${week ? '?weeks=' + week : ''}}`,
        method: 'POST',
        responseType: 'blob', // important
        headers: {
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
}



export const weekgoalDetail = (type, user, week, months) => {
    let query = []
    if (type) {
        query.push(`type=${type}`)
    }
    if (user) {
        query.push(`user=${user}`)
    }

    if (months) {
        query.push(`months=${months.join(',')}`)
    }

    if (week) {
        if (week == 'before') {
            query.push(`date=before`)

        } else {
            query.push(`date=now`)
        }
    }
    return fetch(`${API}/weekgoal/detail${query.length > 0 ? '?' + query.join('&') : ''}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const uploadNewUser = (form) => {

    return axios.post(`${API}/weekgoal/upload`, form,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const uploadNewUserFive = (form) => {

    return axios.post(`${API}/weekgoal/week5/upload`, form,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const getWeekGoal = (type, user, week, months, yesterday, group = undefined) => {
    let query = []
    if (type) {
        query.push(`type=${type}`)
    }
    if (user) {
        query.push(`user=${user}`)
    }

    if (months) {
        query.push(`months=${months.join(',')}`)
    }

    if (week) {
        if (week == 'before') {
            query.push(`date=before`)

        } else {
            query.push(`date=now`)
        }
    }
    if (group) {
        query.push('grouped=true')
    }

    if (yesterday) {
        query.push('yesterday=true')
    }
    return fetch(`${API}/weekgoal${query.length > 0 ? '?' + query.join('&') : ''}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const getWeekGoalById = (type, user, week, months, yesterday, groupid) => {
    let query = []
    if (type) {
        query.push(`type=${type}`)
    }
    if (user) {
        query.push(`user=${user}`)
    }

    if (months) {
        query.push(`months=${months.join(',')}`)
    }
    if (week) {
        if (week == 'before') {
            query.push(`date=before`)

        } else {
            query.push(`date=now`)
        }
    }
    if (yesterday) {
        query.push('yesterday=true')
    }
    return fetch(`${API}/weekgoal/${groupid}${query.length > 0 ? '?' + query.join('&') : ''}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const getAll = (group) => {
    return fetch(`${API}/goal/${group}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const get = () => {
    return fetch(`${API}/goal`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}


export const getVentas = (page) => {
    return fetch(`${API}/goal?page=${page}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const getVentasGrupo = (page) => {
    return fetch(`${API}/goal/getGroup?page=${page || 1}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const getGoalsFromSeller = ({ userId, idGoal, startGoal, endGoal }) => {
    return fetch(`${API_NEXT}/sellersgoals?user=${userId}&startDate=${startGoal}&endDate=${endGoal}&id=${idGoal}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
}

export const getSummaryByPeriod = ({ userIds, startGoal, endGoal }) => {
    const urlTest = 'http://localhost:3000/api/sellersgoals/summaryByPeriod?users=60b6cd2c6563134a4863b962,60db405faa9b6800046bc10b,609eccaf8e2966c543cce9d4&startDate=2021-05-05T13:28:59.667Z&endDate=2021-05-17T13:28:59.667Z&='
    // `${API_NEXT}/sellersgoals/summaryByPeriod?users=${userIds.join(',')}&startDate=${startGoal}&endDate=${endGoal}`
    return fetch(urlTest, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
} 