import react, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Chip,
    Grid,
    Menu,
    MenuItem,
    Typography,
    CardHeader,
    CardContent,
    Box,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import PerformanceCard from "./PerformanceCard";
import { getWeekGoal } from "../../actions/comercial/metas";
import GoalDetail from "./GoalDetail";
import {
    ArrowDownward,
    ArrowDropDown,
    KeyboardArrowDown,
} from "@mui/icons-material";
import moment from "moment";
import { useQuery } from "react-query";
import _ from "lodash";

interface PerformanceInterface {
    user: string;
    selectedUserSyncHistory: any; // Decia user
    group?: any;
}

const defaultGoal = {
    sale: 0,
    saleValue: 0,
    tracing: 0,
    tracingValue: 0,
    salePlus: 0,
    salePlusValue: 0,
    contacts: 0,
    contactsValue: 0,
    emptyGenerated: false,
};

const Performance = ({ user, selectedUserSyncHistory }: PerformanceInterface) => {
    let [loadAgain, setLoadAgain] = useState(false);
    let [dateDisplay, setDateDisplay] = useState<"now" | "before">("now");

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // useEffect(() => {
    //   if(loading){
    //     setLoadAgain(true)
    //   }else{
    //     reload()
    //   }
    // }, [user,dateDisplay])

    // useEffect(() => {
    //   if(!loading && loadAgain){
    //     setLoadAgain(false)
    //     reload()
    //   }
    // }, [loading])

    let reload = async (
        dateDisplay: boolean | "now" | "before" = false,
        retriveType = "calculate"
    ) => {
        let result: any = await getWeekGoal(
            retriveType,
            user ? user : ["all"],
            dateDisplay,
            ["0"]
        );
        let list = [...result.goals.map((r) => ({ ...r, id: r._id }))].sort(
            (a, b) => {
                if (a.year != b.year)
                    return parseInt(a.year) - parseInt(b.year);
                if (a.month != b.month)
                    return parseInt(a.month) - parseInt(b.month);
                if (a.user._id != b.user._id)
                    return `${a.user.name} ${a.user.lastname}`.localeCompare(
                        `${b.user.name} ${b.user.lastname}`
                    );
                return (
                    new Date(a.start).getTime() - new Date(b.start).getTime()
                );
            }
        );
        return { list, users: result.users };
    };

    const [openWeekGoalDetail, setOpenWeekGoalDetail] = useState<
        string | false
    >(false);

    let handleModal = (name) => () => {
        setOpenWeekGoalDetail(name);
    };

    let handleCloseWeekGoalDetail = () => {
        setOpenWeekGoalDetail(false);
    };

    let handlePerformance = (type) => () => {
        setDateDisplay(type);
        handleClose();
    };

    let queryCacheResult = useQuery(
        ["goals-cache", user ? user : ["all"], false, ["0"]],
        () => reload(undefined, "cache")
    );

    let queryCalculatedResult = useQuery(
        ["goals", user ? user : ["all"], false, ["0"]],
        () => reload()
    );
    let result: any;

    let loadNow =
        !queryCacheResult.isLoading && _.get(queryCacheResult, 'data.list', []).length == 0;
    var queryResultNow = useQuery(
        ["goals-now", user ? user : ["all"], "now", ["0"]],
        () => reload("now"),
        {
            enabled: loadNow,
        }
    );

    let queryResult: any = loadNow
        ? queryResultNow
        : queryCalculatedResult.data
        ? queryCalculatedResult
        : queryCacheResult;
    var queryResultBefore = useQuery(
        ["goals-before", user ? user : ["all"], "before", ["0"]],
        () => reload("before"),
        {
            enabled: dateDisplay == "before",
        }
    );

    let loading = queryResult.isLoading;
    if (dateDisplay == "before") {
        loading = queryResultBefore.isLoading;
        if (queryResultBefore.data) result = queryResultBefore.data.list;
    } else {
        let today = new Date();
        if (queryResult.data)
            result = queryResult.data.list.filter(
                (goal) =>
                    new Date(goal.start) < today && new Date(goal.end) > today
            );
    }

    let goalDate = ``;
    if (result && result.length > 0)
        goalDate = `${moment(result[0].start)
            .utcOffset(result[0].offset || -180)
            .format("DD/MM")} - ${moment(result[0].end)
            .utcOffset(result[0].offset || -180)
            .format("DD/MM")}`;
    let goal = { ...defaultGoal };
    if (result) {
        result.map((g) => {
            let aux = {
                ...g.goal[0],
                salePlus: g.goal[1]?.sale ?? 0,
                salePlusValue: g.goal[0]?.saleValue ?? [0],
            };
            Object.keys(aux).map((key) => {
                goal[key] += aux[key];
            });
            goal.emptyGenerated = !!g.generated_void;
        });
    }

    return (
        <>
            <Card
                sx={{
                    boxShadow: "0 0 20px rgb(8 21 66 / 5%)",
                    borderRadius: "10px !important",
                    mt: 0,
                }}
            >
                <CardHeader
                    title={
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                }}
                            >
                                Tu meta{" "}
                                {dateDisplay == "before"
                                    ? "anterior"
                                    : "actual"}
                                <Typography
                                    component="span"
                                    sx={{
                                        color: "gray",
                                        fontSize: "12px",
                                        fontWeight: 800,
                                        ml: 1,
                                        textTransform: "none",
                                    }}
                                >
                                    {loading ? "" : `(${goalDate})`}
                                </Typography>
                            </Typography>
                            <ToggleButtonGroup value={dateDisplay} exclusive>
                                <ToggleButton
                                    value={"now"}
                                    onClick={handlePerformance("now")}
                                    sx={{
                                        py: 0.25,
                                        color:
                                            dateDisplay == "now"
                                                ? "#fff!important"
                                                : "#0080ff!important",
                                        borderColor: "#0080ff!important",
                                        background:
                                            dateDisplay == "now"
                                                ? "#0080ff!important"
                                                : "#fff!important",
                                    }}
                                >
                                    Actual
                                </ToggleButton>
                                <ToggleButton
                                    value={"before"}
                                    onClick={handlePerformance("before")}
                                    sx={{
                                        py: 0.25,
                                        color:
                                            dateDisplay == "before"
                                                ? "#fff!important"
                                                : "#0080ff!important",
                                        borderColor: "#0080ff!important",
                                        background:
                                            dateDisplay == "before"
                                                ? "#0080ff!important"
                                                : "#fff!important",
                                    }}
                                >
                                    Anterior
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    }
                    sx={{ borderBottom: "1px solid #ecf3fa", py: 1, px: 3 }}
                />
                <CardContent>
                    <Grid container direction="row">
                        {!goal.emptyGenerated && (
                            <GoalDetail
                                user={user}
                                open={openWeekGoalDetail}
                                close={handleCloseWeekGoalDetail}
                                dateDisplay={dateDisplay}
                                periodo={goalDate ? goalDate : "Todos"}
                            />
                        )}
                        <PerformanceCard
                            title="Contactos"
                            onClick={handleModal("contacts")}
                            goal={goal.contacts}
                            value={goal.contactsValue}
                            loading={loading}
                            generated={goal.emptyGenerated}
                        />
                        <PerformanceCard
                            title="Seguimientos"
                            selectedUser={selectedUserSyncHistory}
                            onClick={handleModal("tracings")}
                            goal={goal.tracing}
                            value={goal.tracingValue}
                            loading={loading}
                            generated={goal.emptyGenerated}
                        />
                        <PerformanceCard
                            title="Ventas"
                            onClick={handleModal("sales")}
                            goal={goal.sale}
                            goalplus={goal.salePlus}
                            value={goal.saleValue}
                            loading={loading}
                            generated={goal.emptyGenerated}
                        />
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default Performance;
