import react, { useEffect, useState } from "react";
import { getLast, getPerformance } from "../../actions/tracing";
import Router from "next/router";
import Link from "next/link";
import Module from "../core/Module";
import {
    Card,
    CardActionArea,
    Chip,
    CircularProgress,
    Grid,
    Tooltip,
    Typography,
    Box,
    SxProps,
    getListItemSecondaryActionClassesUtilityClass,
} from "@mui/material";
import { CheckCircleOutline, PhoneIphone } from "@mui/icons-material";
import dynamic from "next/dynamic";
import moment from "moment";
import Goal from "./Goal";
import RadialPerformance from "./graphconfigs/RadialPerformanceOld";
import { isAuth } from "../../actions/auth";
import { getLastGroup, getLast as getLastCall } from "../../actions/call";

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });
// import ApexCharts from 'apexcharts'

interface PerformanceCardInterface {
    selectedUser?: any;
    title: string;
    goal: number;
    goalplus?: number;
    value: number;
    loading: boolean;
    generated: boolean;
    onClick: () => void;
}

// const LastCallStatus = ({ selectedUser }) => {
//     var [call, setCall] = useState(null);
//     var colorLlamada = (fechas) => {
//         let f = new Date();
//         let fecha = new Date(f.getFullYear(), f.getMonth(), f.getDate());
//         if (!fechas) return null;
//         if (fecha.getTime() < new Date(fechas).getTime()) return "#28a745";
//         fecha.setDate(fecha.getDate() - 1);
//         if (fecha.getTime() < new Date(fechas).getTime()) return "#ffc107";
//         return "#dc3545";
//     };
//     const msg = {
//         "#28a745": "Tu historial de llamadas esta actualizado al día",
//         "#ffc107": "Tu historial de llamadas esta desactualizado desde ayer",
//         "#dc3545":
//             "Tu historial de llamadas esta desactualizado desde hace días",
//         null: "No tienes llamadas subidas",
//     };
//     const bg = {
//         "#28a745": "#d8f6df",
//         "#ffc107": "#fff2cb",
//         "#dc3545": "#f9dcdf",
//         null: "#e9e9e9",
//     };
//     useEffect(() => {
//         setCall(null);
//         if (selectedUser && isAuth() && selectedUser._id != isAuth()._id) {
//             getLastGroup().then((lista) => {
//                 let c = lista
//                     .map((l) => l._id.user._id)
//                     .indexOf(selectedUser._id);
//                 if (c >= 0) {
//                     c = lista[c];
//                     setCall({
//                         updatedAt: c.ultima_subida,
//                         phoneNumber: c.ultima_llamada ? "0" : null,
//                         color: colorLlamada(
//                             c.ultima_subida ? new Date(c.ultima_subida) : null
//                         ),
//                     });
//                 }
//             });
//         } else {
//             getLastCall().then((c) => {
//                 if (!c) return setCall({ color: null });
//                 setCall({
//                     ...c,
//                     color: colorLlamada(
//                         c.updatedAt ? new Date(c.updatedAt) : null
//                     ),
//                 });
//             });
//         }
//     }, [selectedUser, selectedUser]);

//     return (
//         <Tooltip
//             title={
//                 call
//                     ? msg[call.color]
//                     : "Última actualización de historial de llamadas"
//             }
//         >
//             <Chip
//                 sx={{
//                     fontSize: "10px",
//                     borderRadius: "5px",
//                     p: 0,
//                     height: "22px",
//                     ml: 1,
//                     mb: 0.5,
//                     backgroundColor: call ? bg[call.color] : "#e9e9e9",
//                     color: call ? call.color : "#747474",
//                 }}
//                 label={
//                     call ? (
//                         <PhoneIphone sx={{ fontSize: 16 }} />
//                     ) : (
//                         <CircularProgress
//                             size={16}
//                             sx={{ color: "#747474", mt: "6px" }}
//                         />
//                     )
//                 }
//             />
//         </Tooltip>
//     );
// };

const ranges = [{
    divider: 1E3,
    suffix: 'K'
}, {
    divider: 1E6,
    suffix: 'M'
}, {
    divider: 1E9,
    suffix: 'B'
}];

function formatNumber(input) {
    for (let index = ranges.length - 1; index >= 0; index--) {
        if (input > ranges[index].divider) {
            let quotient = input / ranges[index].divider;

            if (quotient < 10) {
                quotient = Math.floor(quotient * 10) / 10;
            } else {
                quotient = Math.floor(quotient);
            }

            return quotient.toString() + ranges[index].suffix;
        }
    }

    return input.toString();
}
const reloadTotal = (chartContext, config, total_string, value) => {
    if (chartContext)
        if (config.config.series && config.config.series[0] == value) return "";
    ApexCharts.exec(config.globals.chartID, "updateOptions", {
        plotOptions: {
            radialBar: {
                dataLabels: {
                    value: {
                        formatter: function () {
                            return total_string;
                        },
                    },
                },
            },
        },
    });
};

let PerformanceCard = ({
    title,
    goal,
    goalplus,
    value,
    loading,
    onClick,
    generated,
}: PerformanceCardInterface) => {
    let getStringValue = () => {
        return  plus ? formatNumber(value) + "/" + formatNumber(goalplus) : formatNumber(value) + "/" + formatNumber(goal);
    };

    // let [options, setOptions] = useState<any>(defaultOptions)

    let diferencia = goal - value;
    let diferenciaPlus = goalplus - value;
    let porcentaje = goal == 0 ? 100 : Math.floor((value / goal) * 100);
    let plus = false;
    if (porcentaje > 100 && goalplus > 0) {
        porcentaje = Math.floor((value / goalplus) * 100);
        plus = true;
    }

    let options: any = RadialPerformance(title, getStringValue());

    options.plotOptions.radialBar.dataLabels.value.formatter = ((
        totalString: string
    ) => {
        return function () {
            return totalString;
        };
    })(getStringValue());

    options.chart.events = {
        mounted: (chartContext, config) => {
            setTimeout(() => {
                reloadTotal(
                    chartContext,
                    config,
                    plus ? formatNumber(value) + "/" + formatNumber(goalplus) : formatNumber(value) + "/" + formatNumber(goal),
                    porcentaje
                );
            });
        },
        updated: (chartContext, config) => {
            setTimeout(() => {
                reloadTotal(
                    chartContext,
                    config,
                    plus ? formatNumber(value) + "/" + formatNumber(goalplus) : formatNumber(value) + "/" + formatNumber(goal),
                    porcentaje
                );
            });
        },
    };

    options.xaxis.categories = [porcentaje];
    return (
        <Grid item xs={12} md={4}>
            <Box
                px={2}
                sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        borderLeft:
                            title === "Seguimientos"
                                ? "1px #ecf3fa solid"
                                : "none",
                        borderRight:
                            title === "Seguimientos"
                                ? "1px #ecf3fa solid"
                                : "none",
                    },
                    [theme.breakpoints.down("md")]: {
                        pb:
                            title === "Contactos"
                                ? 2
                                : title === "Seguimientos"
                                ? 2
                                : 0,
                        pt:
                            title === "Ventas"
                                ? 2
                                : title === "Seguimientos"
                                ? 2
                                : 0,
                        borderTop:
                            title === "Seguimientos"
                                ? "1px #ecf3fa solid"
                                : "none",
                        borderBottom:
                            title === "Seguimientos"
                                ? "1px #ecf3fa solid"
                                : "none",
                    },
                })}
            >
                <Grid
                    container
                    direction={"column"}
                    justifyContent={"space-between"}
                    alignContent={"left"}
                >
                    <Grid
                        container
                        justifyContent={"space-between"}
                        sx={{ maxWidth: "100%" }}
                    >
                        <Typography variant={"subtitle1"}>
                            {title}
                            {/* {title === "Seguimientos" &&
                            // ((isAuth() &&
                            //     isAuth().roles.indexOf("user") >= 0) ||
                            //     !!selectedUser) ? (
                            //     <LastCallStatus selectedUser={selectedUser} />
                            // ) : (
                            //     ""
                            // )}{" "} */}
                            {goalplus > 0 && diferencia <= 0 ? "plus" : ""}
                        </Typography>
                        {!generated &&
                            !loading &&
                            (diferencia > 0 ? (
                                <Chip
                                    size="small"
                                    color={
                                        porcentaje > 75 ? "warning" : "error"
                                    }
                                    label={
                                        <>
                                            <b>{formatNumber(diferencia)}</b> por completar
                                        </>
                                    }
                                />
                            ) : goalplus > 0 && diferenciaPlus > 0 ? (
                                <Tooltip title="Se completo el objetivo básico">
                                    <div>
                                        <CheckCircleOutline
                                            color="success"
                                            sx={{ fontSize: "18px" }}
                                        />
                                        <Chip
                                            sx={{ ml: 0.5 }}
                                            size="small"
                                            color={
                                                porcentaje > 75
                                                    ? "warning"
                                                    : "error"
                                            }
                                            label={
                                                <>
                                                    <b>{formatNumber(diferenciaPlus)}</b> por
                                                    completar plus
                                                </>
                                            }
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Chip
                                    icon={<CheckCircleOutline />}
                                    size="small"
                                    color="success"
                                    label={
                                        <>
                                            Completado{" "}
                                            {goalplus > 0 ? "plus" : ""}
                                        </>
                                    }
                                />
                            ))}
                    </Grid>
                    {loading ? (
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            sx={{ my: "30px" }}
                        >
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <Grid item onClick={onClick}>
                            <Chart
                                series={[porcentaje]}
                                type="radialBar"
                                options={options}
                            ></Chart>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Grid>
    );
};

export default PerformanceCard;
