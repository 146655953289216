

export default (title, total_string) => (
    {   
        xaxis: {

        },
        chart: {
          id: title+"-performance",
          type: 'radialBar',
          sparkline: {
            enabled: true
          },
          parentHeightOffset: 0
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: '97%',
              margin: 0,
              dropShadow: {
                enabled: true,
                top: 1,
                left: 0,
                color: '#999',
                opacity: 0.5,
                blur: 1
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                formatter: function () {
                    return total_string
                },
                offsetY: -2,
                fontSize: '22px'
              },
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        // fill: {
        //   type: 'gradient',
        //   gradient: {
        //     shade: 'light',
        //     shadeIntensity: 0.4,
        //     inverseColors: false,
        //     opacityFrom: 1,
        //     opacityTo: 1,
        //     stops: [0, 50, 53, 91]
        //   },
        // },
        colors: ["rgb(62,159,251)"],
        // colors: ["#CCEEFF"],
        // fill: {
        //   type: "gradient",
        //   gradient: {
        //     shade: "light",
        //     type: "diagonal1",
        //     shadeIntensity: 0.5,
        //     opacityFrom: 1,
        //     opacityTo: 1,
        //     inverseColors: true,
        //     gradientToColors: ["#4CA8FB"],
        //     // gradientToColors: ["#CCEEFF"],
        //     stops: [0, 100],
            
        //   }
        // },
        // stroke: {
        //   lineCap: "square",
        // },
        labels: ['Average Results'],
      }
)