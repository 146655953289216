import { Add, Close, Delete, Edit, Remove, Source } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { Alert, Box, Card, CardHeader, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Modal, Select, SxProps, TextField, Typography } from "@mui/material"
import { es } from "date-fns/locale"
import moment from "moment"
import { useEffect, useState } from "react"


export interface PersonalizedMonthModalInterface {
    open: boolean,
    onResult: (date:string | false) => void
}



const modalStyle: SxProps = {
    maxWidth: '400px',
    maxHeight: '655px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    background: '#f7f9fb',
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
    pt: 2,
    display: 'grid',
    gridTemplateRows: '40px auto 35px'
}


const PersonalizedMonthModal = ({ open, onResult }: PersonalizedMonthModalInterface) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<false | string>(false)
    const [startValue, setStartValue] = useState(new Date())
    const [endValue, setEndValue] = useState(new Date())

    let handleClose = () => {
        onResult(false)
    }

    let submit = () => {
        let text = moment(startValue).format('MM/YYYY')+'-'+moment(endValue).format('MM/YYYY')
        if(startValue > endValue){
            setError(`La fecha "Desde" debe ser menor a la fecha "Hasta"`)
        }else{
            onResult(text)
        }
    }

    let handleStartValue = (value) => {
        console.log(value)
        setError(false)
        setStartValue(value)
    }

    let handleEndValue = (value) => {
        setError(false)
        setEndValue(value)
    }

    return (
        <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>

        <Modal
            open={!!open}
            onClose={handleClose}
            aria-labelledby="modal-modal-name"
            aria-describedby="modal-modal-description"
        >
            <Grid container sx={modalStyle}>
                <Grid container item justifyContent="space-between" width="100%" direction={"row"}>
                    <Grid item mt={0.5} fontSize={'22px'}>Seleccióna un periodo</Grid>
                    <Grid item>
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item container sx={{width: '100%'}}>

                    <Grid item mt={4} mb={2} sx={{width: '100%'}}>

                <DatePicker
                    views={['year', 'month']}
                    label="Desde"
                    value={startValue}
                    onChange={handleStartValue}
                    renderInput={(params) => <TextField fullWidth {...params} helperText={null} />}
                    />
                    </Grid>
                    <Grid item mb={4} sx={{width: '100%'}}>

                    <DatePicker
                    views={['year', 'month']}
                    label="Hasta"
                    value={endValue}
                    onChange={handleEndValue}
                    renderInput={(params) => <TextField fullWidth {...params} helperText={error ? error : null} error={!!error} />}
                    />
                    </Grid>

                </Grid>
                

                <Grid item xs={12} pt={1}>
                        <LoadingButton loading={loading} style={{ width: '100%' }} variant="contained" color="primary" onClick={submit}>Buscar</LoadingButton>
                </Grid>
            </Grid>
        </Modal >
        </LocalizationProvider>

    )
}

export default PersonalizedMonthModal
